import { template as template_558adb198fb243a5a43a12437fd8c67e } from "@ember/template-compiler";
const SidebarSectionMessage = template_558adb198fb243a5a43a12437fd8c67e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
