import { template as template_3c4c4d8bf4834950aab29124057f339c } from "@ember/template-compiler";
const FKLabel = template_3c4c4d8bf4834950aab29124057f339c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
