import { template as template_dbe7f490e88c4d639246b6a797ad5cf5 } from "@ember/template-compiler";
const FKText = template_dbe7f490e88c4d639246b6a797ad5cf5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
